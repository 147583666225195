.App {
  text-align: center;
  font-family: "Poppins","Prompt", sans-serif;
}
p,li,ul,ol,h1,h2{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
.back-home {
  position: absolute;
  z-index: 99;
  right: 30px;
  bottom: 30px;
}

.text-primary-color{
  color: #115873 !important;
}

.text-secondary-color {
  color: #50727F !important;
}

.btn-color{
  color: #115873 !important;
  transition: 0.3s;
  border-radius: 6px;
}

.btn-color:hover{
  color: #21D0CE !important;
  transition: 0.3s;
  border-color: #21D0CE !important;
  border-radius: 6px;
}

.w-full {
  width: 100%;
}

.align-left{
  text-align: left;
}

.ant-card-bordered {
border-radius: 6px !important;
}
