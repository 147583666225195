
body {
  margin: 0;
  font-family: "Poppins","Prompt", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins","Prompt", sans-serif;

}

.ant-btn.ant-btn-background-ghost, .ant-dropdown-button {
  border: 0px !important;
}

.btn-exit{
  color: #D9534F !important;
}

.text-header-color {
  color: #50727F !important;
  
}

.label-header{
  display: block;
  
}

@media (max-width: 450px) {
  .label-header{
    display: none !important;

  }

  .avatar .center-name {
    font-size: 18px !important;
  }

  .avatar-list.text-xs .center-name {
    font-size: 5px !important;
  }
  
  .viewport .video-container.video-container-in-sharing{
    width: 64px !important;
  }

}

